export const GET_LOGIN_DATA = 'GET_LOGIN_DATA';
export const GET_FREQUENT_PURCHASES = 'GET_FREQUENT_PURCHASES';
export const GET_USER_METRICS = 'GET_USER_METRICS';
export const GET_SEARCH_RESULT = 'GET_SEARCH_RESULT';
export const GET_SPECIFIC_HOSPITAL_DATA = 'GET_SPECIFIC_HOSPITAL_DATA';
export const GET_SPECIFIC_DIAGNOSTIC_DATA = 'GET_SPECIFIC_DIAGNOSTIC_DATA';
export const GET_LAST_PRESCRIPTION = 'GET_LAST_PRESCRIPTION';
export const GET_POCID = 'GET_POCID';
export const GET_UPCOMING_CONSULTATION = 'GET_UPCOMING_CONSULTATION';
export const GET_VIDEO_SESSION_DETAIL = 'GET_VIDEO_SESSION_DETAIL';
export const GET_RATING_DETAILS = 'GET_RATING_DETAILS';
export const SET_SERVICE_CATEGORIES = 'SET_SERVICE_CATEGORIES';
export const GET_PHLEBO_ORDER_IN_PROGRESS = 'GET_PHLEBO_ORDER_IN_PROGRESS';
export const PRIVILEGE_PROFILE_DATA = 'PRIVILEGE_PROFILES_DATA';
export const PC_CARD_BENEFITS = 'PC_CARD_BENEFITS';