import StorageUtil from '../../auth/util/storageUtil';
import {
    DEPARTMENT_ID, DIAG_PACKAGE_SERVICE_ID, DIAG_PRES_UPLOADED, DIAG_SPECIAL_TEST_PRES, DIAG_SPECIAL_TEST_PRES_DETAILS_UPLOADED_LOCALLY, FETCH_CATEGORY_FILTERED_DIAG_HC_TEST, FETCH_CATEGORY_FILTERED_DIAG_WALKIN_TEST,
    FETCH_DIAGNOSTIC_CENTRES_LIST,
    FETCH_FOOTER_DIAGNOSTIC_CENTRES_LIST,
    FETCH_DIAGNOSTIC_CENTRE_DETAILS,
    FETCH_DIAG_HC_FILTERED_LIST,
    FETCH_DIAG_HC_MOST_BOOKED_SUCCESS,
    FETCH_DIAG_HC_PACKAGES_SUCCESS,
    FETCH_DIAG_HC_PROFILES, FETCH_DIAG_HC_TEST_SUCCESS,
    FETCH_DIAG_HOME_ADVERTISEMENT_LIST_SUCCESS, FETCH_DIAG_PACKAGES_SUCCESS, FETCH_DIAG_PACKAGE_DETAILS,
    FETCH_DIAG_PROFILES, FETCH_DIAG_WALKIN_ADVERTISEMENT_LIST_SUCCESS,
    FETCH_DIAG_WALKIN_FILTERED_LIST, FETCH_DIAG_WALKIN_MOST_BOOKED_SUCCESS, FETCH_DIAG_WALKIN_TEST_CATEGORIES_SUCCESS, FETCH_DIAG_WALKIN_TEST_SUCCESS,
    FETCH_HC_SELECTED_POC, FETCH_HEALTH_CU_ADV_LIST, FETCH_HEALTH_CU_CAT_LIST, FETCH_HEALTH_CU_PACK_LIST, FETCH_HEALTH_CU_SELECTED_CAT, FETCH_IF_DIAG_HOMECOLLECTION, FETCH_IF_MOST_BOOKED, FETCH_POC_LIST, FETCH_SELECTED_POC, FETCH_SELECTED_SLOT_DETAILS,
    FETCH_SELECTED_SLOT_DETAILS2,
    FETCH_STATE_OF_CENTRES_LIST_PAGE,
    FETCH_WALKIN_LISTVIEW_ITEM, HC_FILE_URLS, HC_TEST_CAT, IS_FETCHING, IS_SPLIT_FOR_PACKAGE, IS_SPLIT_FOR_POST_PRANDIAL, LIST_VIEW_TYPE,
    SELECTED_DEPARTMENT,
    SERVICE_CARD_DATA,
    SET_ALL_SERVICES,
    SET_HC_SELECTED_CATEGORIES, SET_SELECTED_CATEGORIES,
    SET_SELECTED_SERVICE_LIST, SET_VDC_SERVICE_ADVS, SLOT_SELECTED_SERVICE_LIST, VDC_PACKAGE_SUGGESTIONS, VDC_TEST_DETAILS, VDC_TEST_SUGGESTIONS, WALKIN_CAT,
    REGISTERED_USERS, SET_SELECTED_USER_DETAILS
} from './diagnostic.types';

const diagnosticsReducer = (state: any = {}, action: any) => {
    let selectedServiceList: Map<number, any> = new Map<number, any>();
    let isHomeCollection: boolean;

    switch (action.type) {
        //common
        case FETCH_IF_DIAG_HOMECOLLECTION:
            return {
                ...state,
                isHomeCollection: action.payload
            };
        /* case FETCH_DIAG_WALKIN_ADVERTISEMENT_LIST_SUCCESS:
            return {
                ...state,
                diagnosticAdvertisementList: action.payload,
                loadingDiagnosticAdvertisementList: false,
                error: ''
            }; */



        case FETCH_WALKIN_LISTVIEW_ITEM:
            return {
                ...state,
                item: action.payload
            };
        case FETCH_IF_MOST_BOOKED:
            return {
                ...state,
                categoryType: action.payload
            };

        case IS_FETCHING:
            return {
                ...state,
                isFetching: action.payload
            }

        case LIST_VIEW_TYPE:
            return {
                ...state,
                listViewType: +action.payload
            }
        case DEPARTMENT_ID:
            return {
                ...state,
                departmentId: +action.payload
            }
        case DIAG_PACKAGE_SERVICE_ID:
            return {
                ...state,
                diagPackageServiceId: action.payload
            }
        case FETCH_DIAG_PACKAGE_DETAILS:
            return {
                ...state,
                diagPackageDetails: action.payload
            }
        case FETCH_SELECTED_SLOT_DETAILS:
            return {
                ...state,
                selectedSlotDetails: action.payload
            }
        case FETCH_SELECTED_SLOT_DETAILS2:
            return {
                ...state,
                selectedSlotDetails2: action.payload
            }
        case SLOT_SELECTED_SERVICE_LIST:
            return {
                ...state,
                slotBasedServiceList: action.payload
            }
        case FETCH_DIAGNOSTIC_CENTRES_LIST:
            return {
                ...state,
                diagnosticCentresList: action.payload
            }
        case FETCH_FOOTER_DIAGNOSTIC_CENTRES_LIST:
            return {
                ...state,
                diagnosticFooterCentresList: action.payload
            }
        case FETCH_DIAGNOSTIC_CENTRE_DETAILS:
            return {
                ...state,
                diagnosticCentreDetails: action.payload
            }
        case FETCH_STATE_OF_CENTRES_LIST_PAGE:
            return {
                ...state,
                diagnosticCentreListPageState: action.payload
            }
        case DIAG_PRES_UPLOADED:
            return {
                ...state,
                diagPresStatus: action.payload,
            }
        case DIAG_SPECIAL_TEST_PRES:
            return {
                ...state,
                diagSpecialTestPres: action.payload
            }
        case DIAG_SPECIAL_TEST_PRES_DETAILS_UPLOADED_LOCALLY:
            return {
                ...state,
                locallyUploadedPrescriptionList: action.payload
            }

        case SERVICE_CARD_DATA:
            return {
                ...state,
                diagServiceData: action.payload
            }
        case FETCH_POC_LIST:
            return {
                ...state,
                pocList: action.payload
            }

        case IS_SPLIT_FOR_PACKAGE:
            return {
                ...state,
                splitForPackage: action.payload
            }
        case IS_SPLIT_FOR_POST_PRANDIAL://spiltForPostPrandial
            return {
                ...state,
                spiltForPostPrandial: action.payload
            }

        case 'setdiagnosticInitialState'://use this for modifying localstorage save behaviour
            selectedServiceList = StorageUtil.getDataLocalStorage(StorageUtil.TYPE_SELECTED_SERVICE_LIST);

            isHomeCollection = action?.payload?.isHomeCollection
            let { diagnosticAdvertisementList, diagnosticWalkinMostBooked,
                diagnosticPackages, diagnosticWakinTestList,
                diagnosticHCTestList, diagnosticHCPackages,
                diagnosticHCMostBooked, diagHomeAdvList, isSinglePOC, diagServiceData } = state;


            return {
                ...action.payload,
                selectedServiceList,

                //skipping for from localstorage
                diagnosticAdvertisementList,
                diagnosticWalkinMostBooked,
                diagnosticPackages,
                diagnosticWakinTestList,
                //HC
                diagnosticHCTestList,
                diagnosticHCPackages,
                diagnosticHCMostBooked,
                diagHomeAdvList,
                isSinglePOC,
                diagServiceData
            } //`set${reucerNameInCombineReducer}InitialState` is used to set initial state of reducer

        case 'SET_SELECTED_SLOT_PRICES':
            return {
                ...state,
                slotBasedPrices: action.payload
            }

        case 'IS_SINGLE_POC':
            return {
                ...state,
                isSinglePOC: action.payload
            }
        case 'IS_FROM_MAIN_DASHBOARD':
            return {
                ...state,
                isFromMainDashboard: action.payload
            }
        case 'SET_SERVICE_TESTS':
            return {
                ...state,
                serviceTests: action.payload
            }
        case REGISTERED_USERS:
            return {
                ...state,
                registeredUsers: action.payload
            };
        case SET_SELECTED_USER_DETAILS:
            return {
                ...state,
                selectedUsersMap: action.payload
            };
        //walkin
        case FETCH_DIAG_WALKIN_TEST_SUCCESS:
            return {
                ...state,
                diagnosticWakinTestList: action.payload,
            };
        case FETCH_DIAG_WALKIN_MOST_BOOKED_SUCCESS:
            return {
                ...state,
                diagnosticWalkinMostBooked: action.payload,
            };
        case FETCH_DIAG_PACKAGES_SUCCESS:
            return {
                ...state,
                diagnosticPackages: action.payload,
            };
        case SET_SELECTED_CATEGORIES:
            return {
                ...state,
                selectedCategoryItem: action.payload
            };
        case FETCH_DIAG_WALKIN_TEST_CATEGORIES_SUCCESS:
            return {
                ...state,
                diagnosticWakinTestCategories: action.payload,
            };
        case FETCH_CATEGORY_FILTERED_DIAG_WALKIN_TEST://vdc
            return {
                ...state,
                categoryFilteredServiceList: action.payload
            };
        case FETCH_SELECTED_POC:
            return {
                ...state,
                selectedPoc: action.payload
            };
        case SET_SELECTED_SERVICE_LIST:
            if (action.payload && action.payload.selectedServiceList) {
                StorageUtil.setDataLocalStorage(StorageUtil.TYPE_SELECTED_SERVICE_LIST, action.payload.selectedServiceList);
            }
            return {
                ...state,
                selectedServiceList: action.payload.selectedServiceList,
                isHomeCollection: action.payload.isHomeCollection ? action.payload.isHomeCollection : false
            };

        case FETCH_DIAG_WALKIN_ADVERTISEMENT_LIST_SUCCESS:
            return {
                ...state,
                diagnosticAdvertisementList: action.payload,
            };
        case FETCH_DIAG_WALKIN_FILTERED_LIST:
            return {
                ...state,
                diagWalkinFilteredTestList: action.payload,
            };
        case FETCH_DIAG_PROFILES:
            return {
                ...state,
                diagnosticProfiles: action.payload,
            };

        //home collection
        case FETCH_DIAG_HC_TEST_SUCCESS:
            return {
                ...state,
                diagnosticHCTestList: action.payload,
            };
        case FETCH_DIAG_HC_MOST_BOOKED_SUCCESS:
            return {
                ...state,
                diagnosticHCMostBooked: action.payload,
            };
        case FETCH_DIAG_HC_PACKAGES_SUCCESS:
            return {
                ...state,
                diagnosticHCPackages: action.payload,
            };
        case SET_HC_SELECTED_CATEGORIES:
            return {
                ...state,
                selectedHCCategoryItem: action.payload
            };
        /* case FETCH_DIAG_HC_TEST_CATEGORIES:
            return {
                ...state,
                diagnosticWakinTestCategories: action.payload,
            }; */
        case FETCH_CATEGORY_FILTERED_DIAG_HC_TEST://vdc
            return {
                ...state,
                categoryHCFilteredServiceList: action.payload
            };
        case FETCH_HC_SELECTED_POC:
            return {
                ...state,
                selectedHCPoc: action.payload
            }
        case HC_FILE_URLS:
            return {
                ...state,
                selectedHCFileUrls: action.payload
            }
        case FETCH_DIAG_HOME_ADVERTISEMENT_LIST_SUCCESS:
            return {
                ...state,
                diagHomeAdvList: action.payload,
            };
        case FETCH_DIAG_HC_FILTERED_LIST:
            return {
                ...state,
                diagHCFilteredTestList: action.payload,
            };
        case FETCH_DIAG_HC_PROFILES:
            return {
                ...state,
                diagnosticHCProfiles: action.payload,
            };

        //health checkup
        case FETCH_HEALTH_CU_ADV_LIST:
            return {
                ...state,
                healthAdvList: action.payload,
            };
        case FETCH_HEALTH_CU_CAT_LIST:
            return {
                ...state,
                healthCat: action.payload,
            };
        case FETCH_HEALTH_CU_PACK_LIST:
            return {
                ...state,
                healthPackages: action.payload,
            };
        case FETCH_HEALTH_CU_SELECTED_CAT:
            return {
                ...state,
                healthSelCat: action.payload,
            };

        // Our Services
        case SET_ALL_SERVICES:
            return {
                ...state,
                allServices: action.payload
            };
        case SET_VDC_SERVICE_ADVS:
            return {
                ...state,
                serviceAdvs: action.payload
            }

        // VDC walkin
        case WALKIN_CAT:
            return {
                ...state,
                walkinCats: action.payload
            }
        case VDC_TEST_DETAILS:
            return {
                ...state,
                vdcTestDetails: action.payload
            }
        case VDC_TEST_SUGGESTIONS:
            return {
                ...state,
                vdcTestSuggestions: action.payload
            }
        case VDC_PACKAGE_SUGGESTIONS:
            return {
                ...state,
                vdcPackTestSugg: action.payload
            }

        // VDC HC
        case HC_TEST_CAT:
            return {
                ...state,
                HCTestCats: action.payload
            }
        case SELECTED_DEPARTMENT:
            return {
                ...state,
                selectedDepartment: action.payload
            }

        default:
            return state;
    }
};

export default diagnosticsReducer;